<template>
  <div>
    <!-- 发货单设置弹窗 -->
     <template>
      <my-dialog-2
        :centerDialogVisible="dialogFahuoVisible"
        @updateVisible="updateFahuoVisible"
        @openDialog="handleOpenFahuoDialog"
        @print="doPrintFahuo"
      >
        <div class="fahuo-dialog">
          <div class="left-iframe">
            <!-- 发货单预览弹窗 -->
            <iframe id="fahuo_iframe" width="800px" height="560px">
              <p>当前浏览器不支持iframe标签</p>
            </iframe>
          </div>
          <div class="right-menu">
            <el-button @click="FahuoGlobalSettings" class="menu-btn1" size="mini">全局设置</el-button>
            <!-- <ul class="radio-list">
              <li class="radio-item">
                <span style="margin-left: 10px">纸张样式</span>
              </li>
              <el-radio-group
                v-model="activeFahuoIndex"
                @change="handleRadioChange"
              >
                <el-radio
                  style="margin:5px 0px;"
                  v-for="(item, index) in fahuoTemplateList"
                  :key="index"
                  :label="index + ''"
                  >{{ item.express_name }}</el-radio
                >
              </el-radio-group>
            </ul>
            <el-button
              class="menu-btn2"
              type="primary"
              size="mini"
              @click="setFahuoTemplate"
              >确认</el-button
            > -->
          </div>
        </div>
      </my-dialog-2>
      <!-- 发货单全局设置 -->
      <my-dialog-5
        dialogTitle="发货单"
        :centerDialogVisible="dialogFahuoGlobalSettingsVisible"
        @updateVisible="updateFahuoGlobalSettingsVisible"
        @resetPopupData="updateFahuoGlobalSettingsVisible(false)"
        @submitPopupData="saveFahuoGlobalSettingsDialog"
      >
        <template slot="content">
          <div class="ExpressGlobalSettingsBox">
            <div class="ExpressGlobalSettingsBoxLeft">
              <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <!-- <span style="color:#fff;font-size: 14px;">1</span> -->
                <span style="color: #f39f27;margin-left:10px;">发货单设置</span>
              </div>
              <div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">发货单标题</div>
                  <input v-model="info_Invoice.title" class="FahuoGlobalSettingsItemInput" type="text" placeholder="发货单标题"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">经办人</div>
                  <input v-model="info_Invoice.operator" class="FahuoGlobalSettingsItemInput" type="text" placeholder="经办人"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">店铺名称</div>
                  <input v-model="info_Invoice.shop_name" class="FahuoGlobalSettingsItemInput" type="text" placeholder="店铺名称"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">联系电话</div>
                  <input v-model="info_Invoice.phone" onkeyup="this.value=this.value.replace(/\D/g,'')" maxlength="11" class="FahuoGlobalSettingsItemInput" type="text" placeholder="联系电话"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">发件地址</div>
                  <input v-model="info_Invoice.address" class="FahuoGlobalSettingsItemInput" type="text" placeholder="发件地址"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">友情提示</div>
                  <textarea v-model="info_Invoice.tips" class="FahuoGlobalSettingsItemTextarea" type="text" placeholder="友情提示"/>
                </div>
              </div>
              <!-- <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">2</span>
                <span style="color: #f39f27;margin-left:10px;">默认字体字号</span>
              </div> -->
              <!-- <div class="content-subcontent" style="padding: 5px 0">
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">默认字体</div>
                  <el-select v-model="FahuoGlobalSettingsFontFamily" size="mini" style="width:80px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">默认字号</div>
                  <el-select v-model="FahuoGlobalSettingsFontSize" size="mini" style="width:80px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-select v-model="FahuoGlobalSettingsFontWeight" size="mini" style="width:80px;margin-left:20px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <div class="FahuoGlobalSettingsItemTips">注意：默认字号或加粗将影响所有模版，太大或太小都可能导致打印不全，请谨慎调试</div>
                </div>
              </div> -->
            </div>
            <!-- <div class="ExpressGlobalSettingsBoxRight"> -->
              <!-- <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">3</span>
                <span style="color: #f39f27;margin-left:10px;">打印整体缩放</span>
              </div>
              <div class="ExpressGlobalSettingsZoomBox">
                <span style="color: #999999;">横向缩放</span>
                <el-slider style="width:190px;margin: 0px 10px;" v-model="FahuoGlobalSettingsScaleZoom_Transverse" :show-tooltip="false"></el-slider>
                <span style="color: #999999;">{{FahuoGlobalSettingsScaleZoom_Transverse}}%</span>
              </div>
              <div class="ExpressGlobalSettingsZoomBox">
                <span style="color: #999999;">纵向缩放</span>
                <el-slider style="width:190px;margin: 0px 10px;" v-model="FahuoGlobalSettingsScaleZoom_Longitudinal" :show-tooltip="false"></el-slider>
                <span style="color: #999999;">{{FahuoGlobalSettingsScaleZoom_Longitudinal}}%</span>
              </div> -->
            <!-- </div> -->
          </div>
        </template>
      </my-dialog-5>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { lodopPreview } from "@/utils/functions.js";
import myDialog2 from "@/UI/myDialog2";
import myDialog5 from "@/UI/myDialog5";
export default {
  components:{
    myDialog2,
    myDialog5
  },
  data() {
    return {
    // 发货单全局设置
    dialogFahuoGlobalSettingsVisible: false,
    FahuoGlobalSettingsFontFamily:"宋体",
    FahuoGlobalSettingsFontSize:"14",
    FahuoGlobalSettingsFontWeight:"加粗",
    FahuoGlobalSettingsScaleZoom_Transverse:50, // 发货单横向缩放
    FahuoGlobalSettingsScaleZoom_Longitudinal:50, // 发货单横向缩放
    };
  },
  computed: { 
    ...mapState({
        info_Invoice: (state) => state.setting.invoice_list,
        // 打印参数
        printOrderInfo: (state) => state.batchprint.printOrderInfo,
    }),
    dialogFahuoVisible:{
      get() {
          return this.$store.state.batchprint.dialogFahuoVisible;
      },
    }
  },
  methods: {
    FahuoGlobalSettings(){
        this.updateFahuoVisible(false)
        this.dialogFahuoGlobalSettingsVisible = true
    },
    updateFahuoGlobalSettingsVisible(val){
        this.showFahuoDialog()
        this.dialogFahuoGlobalSettingsVisible = val
    },
    saveFahuoGlobalSettingsDialog(){
        this.$store.dispatch("setting/editAddressInvoice",this.info_Invoice);
        this.dialogFahuoGlobalSettingsVisible = false
    },
    FahuoGlobalSettingsItemAllSetKDListItemAddFahuo(){
        this.openAddFahuoVisible()
    },
    updateFahuoVisible(val) {
        this.$store.commit('batchprint/SAVE',{dialogFahuoVisible:val})
    },
    handleOpenFahuoDialog() {
        lodopPreview(
        this.LODOP,
        "5",
        "fahuo_iframe",
        this.printOrderInfo,
        "",
        "",
        "",
        );
    },
    handleRadioChange(lable) {
        this.activeFahuoIndex = lable;
        lodopPreview(
        this.LODOP,
        "5",
        "fahuo_iframe",
        this.printOrderInfo,
        "",
        "",
        ""
        );
    },
    setFahuoTemplate() {
        this.$store.commit('batchprint/SAVE',{dialogFahuoVisible:false})
    },
    // 打印函数
    doPrintFahuo() {
        this.$message.error("请使用下方打印按钮进行打印！");
    },
    // 发货全局设置弹窗
    showFahuoDialog(){
        this.$store.commit('batchprint/SAVE',{dialogFahuoVisible:true})
    },
  },
}
</script>
<style lang="less" scoped>
.ExpressGlobalSettingsBox{
  // display: flex;
  height: 100%;
}
.ExpressGlobalSettingsBoxLeft{
  height: 430px;
  // height: 475px;
  // width: 390px;
  // border-right: 1px solid #6b6e76;
}
.ExpressGlobalSettingsLabel{
  margin-bottom: 14px;
  padding-left: 42px;
}
.FahuoGlobalSettingsItemName{
  display: inline-block;
  width: 70px;
  text-align: end;
  margin-right: 20px;
  color: #999999;
}
.FahuoGlobalSettingsItemInput{
  width: 200px;
  padding: 2px 5px;
  outline:none;
  font-size: 12px;
}
.FahuoGlobalSettingsItemTextarea{
  padding: 2px 5px;
  font-size: 12px;
  outline:none;
  width: 202px;
  height: 48px;
  resize: none;
  vertical-align: top;
}
.FahuoGlobalSettingsItemTips{
  margin: 20px 20px 0px 0px;
  color: #999999;
}

</style>