<template>
  <div>
    <my-search-form
      :foldButton="true"
      :formData="searchFormData"
      @updateFormData="updateFormData"
      @submitSearch="submitSearch"
    >
    </my-search-form>
    <div class="bg-container">
      <div class="content">
        <div class="top-menu">
          <div class="menu-item" @click="showExpressDialog">
            <el-image
              style="width: 50px; height: 50px"
              :src="OSS+'/expressbill_setting.png'"
              fit="fill"
            ></el-image>
            <span>快递单设置</span>
          </div>
          <div class="menu-item" @click="showFahuoDialog">
            <el-image
              style="width: 50px; height: 50px"
              :src="OSS+'/Invoice_setting.png'"
              fit="fill"
            ></el-image>
            <span>发货单设置</span>
          </div>
        </div>
        <!-- 新手指导 -->
        <!-- NewUserShow -->
        <NewUser v-if="NewUserShow" v-bind:setType="setType"/>
        <!-- 未查询时的缺省 -->
        <div v-if="dataType" class="noDataClass">
          <div style="flex-direction: column;align-items: flex-start;cursor: pointer;" @click="textJump">
            <!-- <div>
              <p></p>
              <p>如何使用快手电子面单？</p>
            </div> -->
            <div>
              <p></p>
              <p>如何使用快手电子面单打印？</p>
            </div>
          </div>
        </div>
        <!-- component占位 -->
        <component :is="isShow"></component>
        <div class="serviceBox" v-if="NewUserShow" :style="showHiddenData=='显示'?'right:-20px':'right:20px'">
          <img class="service_bg_two" :src="OSS+'/service_photo.png'" alt="">
          <span class='service_bg_span' style="bottom: 48px;" @click="handleService">客服</span>
          <span class='service_bg_span' @click="handleShowHidden" style="bottom: 12px;">{{showHiddenData}}</span>
          <img v-if="bigtext" class="service_bgtext" @click="handleShowHidden" :src="OSS+'/clickBig.png'" alt="">
          <div class="service_qr" v-if="serviceShow">
            <i class="el-icon-close service_qr_close" @click="handleService"></i>
            <p class="service_qr_text1">咨询客服</p>
            <p class="service_qr_text2">微信扫码联系客服</p>
            <div style="text-align: center;">
              <img class="service_qr_img" :src="OSS+'/erweima.png'" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 发货单设置弹窗 -->
    <DeliverGoodsSettingDialog />
   <!-- 快递单设置弹窗 -->
   <ExpressSettingDialog />
    <!-- 查询进度条的弹窗 -->
    <progressBarPopup v-if="progressBarPopup!==false" :data="progressBarPopup" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { lodopPreview } from "@/utils/functions.js";
import myDialog2 from "@/UI/myDialog2";
import myDialog4 from "@/UI/myDialog4";
import myDialog5 from "@/UI/myDialog5";
import mySearchForm from "@/UI/mySearchForm";
import progressBarPopup from "@/UI/progressBarPopup";
import index from "@/components/batchprint/index";
import ResultList from "@/components/batchprint/ResultList";
import NewUser from "@/components/newUser/index";

import DeliverGoodsSettingDialog from "@/components/allSettingsDialog/deliverGoodsSettingDialog";
import ExpressSettingDialog from "@/components/allSettingsDialog/expressSettingDialog";
export default {
  components: {
    myDialog2,
    myDialog4,
    myDialog5,
    mySearchForm,
    index,
    ResultList,
    NewUser,
    progressBarPopup,
    ExpressSettingDialog,//快递单设置弹窗
    DeliverGoodsSettingDialog,//发货单设置弹窗
  },
  data() {
    return {
      serviceShow:true,
      bigtext:false,
      showHiddenData:'隐藏',
      activeFahuoIndex: "0",
      // 默认的渲染窗口
      activeExpressIndex: "0",
      dialogFahuoVisible: false,
      dialogExpressVisible: false,
      dialogAddExpressTemplateVisible: false,
      dialogExpressGlobalSettingsVisible: false,
      expressTemplateListTemp: null,
      SelectedExpressTemplateModifySequenceLabel:0,
      ExpressGlobalSettingsFontFamily:"宋体",
      ExpressGlobalSettingsFontSize:"14",
      ExpressGlobalSettingsFontWeight:"加粗",
      ExpressGlobalSettingsScaleZoom_Transverse: 50, // 快递单横向缩放
      ExpressGlobalSettingsScaleZoom_Longitudinal: 50, // 快递单纵向缩放


      // 发货单全局设置
      FahuoGlobalSettingsDialog: true,
      dialogFahuoGlobalSettingsVisible: false,
      FahuoGlobalSettingsFontFamily:"宋体",
      FahuoGlobalSettingsFontSize:"14",
      FahuoGlobalSettingsFontWeight:"加粗",
      FahuoGlobalSettingsScaleZoom_Transverse:50, // 发货单横向缩放
      FahuoGlobalSettingsScaleZoom_Longitudinal:50, // 发货单横向缩放
      setType: [false, false, false],
      NewUserShow: false,
      WaybillSiteInfoItemSelected: "", // 默认选中快递网点索引
    };
  },
  mounted() {
    this.searchFormData_temp = JSON.parse(JSON.stringify(this.searchFormData));
    this.$store.dispatch("batchprint/get_search_result",true);
    this.$store.dispatch("batchprint/GetTaobaoAuthURL");
    this.$store.dispatch("print/getFieldData")
    this.$store.commit("batchprint/SET_ISSHOW", "");
    this.$store.dispatch("setting/getConfig").then((res) => {
      // this.setType[0] = false
      // this.setType[1] = this.info_Invoice.ctime ?true :false
      this.setType[0] = res.data.express_list.length > 0 ?true :false
      this.setType[1] = true
      this.setType[2] = (this.PRINTERLIST && window.ks_print_list) ?true :false
      // this.contentShow = true
      if(this.setType.indexOf(false) !== -1) {
        this.NewUserShow = true
        this.$store.commit("batchprint/SET_DATATYPE", false);
      }else {
        this.NewUserShow = false
        this.$store.commit("batchprint/SET_DATATYPE", true);
      }
      console.log(this.setType)
    })
  },
   computed: {
    ...mapState({
      dataType: (state) => state.batchprint.dataType,
      isShow: (state) => state.batchprint.isShow,
      searchFormData: (state) => state.batchprint.searchFormData,
      fahuoTemplateList: (state) => state.print.fahuoTemplateList,
      // 打印参数
      printExpressTemplate: (state) => state.batchprint.printExpressTemplate,
      printExpressBgimg: (state) => state.batchprint.printExpressBgimg,
      printFahuoTemplate: (state) => state.batchprint.printFahuoTemplate,
      printFahuoBgimg: (state) => state.batchprint.printFahuoBgimg,
      progressBarPopup:(state) => state.batchprint.progressBarPopup,

     
      express_list: (state) => state.setting.express_list,
    }),
    addExpressId: {
      get() {
        return this.$store.state.print.addExpressId;
      },
      set(val) {
        this.$store.commit("print/SET_ADD_EXPRESS_ID", val);
      },
    },
    expressTemplate: {
      get() {
        return this.$store.state.batchprint.expressTemplate;
      },
      set(val) {
        this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val+'');
      }
    }
  },
  methods: {
    handleService(){
      this.serviceShow=!this.serviceShow
    },
    handleShowHidden(){
      if (this.showHiddenData=='隐藏') {
        this.showHiddenData='显示'
        this.bigtext=true
      }else{
        this.showHiddenData='隐藏'
        this.bigtext=false
      }
    },
    // searchForm函数
    // 更新表单数据
    updateFormData(val) {
      console.log(val,'---val');
      this.searchFormData = val;
    },
    // 点击查询按钮
    submitSearch() {
      this.NewUserShow = false
      this.$store.commit("batchprint/SAVE",{is_shipped:0})
      this.searchFormData.pull_data = 1 // 标识是否拉取新数据
      this.$store.dispatch("batchprint/get_search_result").then(res=>{
        this.searchFormData.pull_data = 0 // 使用完成后还原
      });
      this.$store.commit("batchprint/SET_ISSHOW", "ResultList");
      this.$store.commit("batchprint/SET_DATATYPE", false);
      
    }, 
    showExpressDialog() {
      this.$store.commit('batchprint/SAVE',{dialogExpressVisible:true})
    },
    // 发货全局设置弹窗
    showFahuoDialog(){
      this.$store.commit('batchprint/SAVE',{dialogFahuoVisible:true})
    },
    textJump() {
      let path = `${window.location.protocol}//${window.location.host}/#/usetutorial`
      window.open(path);
    },
  }
};
</script>

<style lang="less" scoped>
.serviceBox {
    z-index: 2000;
    position: fixed;
    bottom: 50px;
    right: 20px;
    width: 256px;
    height: 237px;
    .service_qr {
      width: 261px;
      height: 339px;
      position: absolute;
      bottom: 0;
      right: 67px;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      pointer-events: auto;
    .service_qr_close {
      width: 9px;
      height: 9px;
      color: #afafaf;
      margin-left: 235px;
      margin-top: 12px;
      cursor: pointer;
    }
    .service_qr_text1 {
      margin: 0;
      margin-top: 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #333;
      text-align: center;
    }
    .service_qr_text2 {
      margin: 0;
      margin-top: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #767989;
      text-align: center;
    }
    .service_qr_img {
      width: 203px;
      height: 203px;
      margin-top: 25px;
      text-align: center;
    }
  }
  .service_bg_two {
    height: 125px;
    width: 47px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .service_bg_span {
    position: absolute;
    right: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    color: #767989;
    pointer-events: auto;
  }
  .service_bgtext {
    width: 76px;
    height: 28.5px;
    position: absolute;
    bottom: 97.5px;
    cursor: pointer;
    pointer-events: auto;
    right: 34px;
    z-index: 2;
  }
}
.bg-container {
  min-height: 500px;
  background: linear-gradient(to bottom, #e6e0d0 0%, #ffffff 100%);
  padding: 10px;
  display: flex;
  flex-direction: row;
  padding-bottom: 0px;
}
.content {
  position: relative;
  width: 100%;
  min-width: 1200px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 12px;
  padding-bottom: 0px;
}
.top-menu {
  position: absolute;
  top: 20px;
  right: 50px;
  display: flex;
  flex-direction: row;
  .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    color: #c6bb9d;
    cursor: pointer;
  }
}

.noDataClass {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  div {
    display: flex;
    align-items: center;
    p:nth-child(1) {
      width: 5px;
      height: 5px;
      background-color: #f5821f;
      border-radius: 50%;
      margin-right: 10px;
    }
    p:nth-child(2) {
      color: #333;
      font-size: 16px;
      line-height: 16px;
    }
  }
  div:nth-child(2) {
    padding-top: 15px;
  }
}
</style>