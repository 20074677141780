<template>
  <div class="index-container">
    <ul class="index-list">
      <!-- <li class="list-item">
        <i class="iconfont icon-circle"></i>
        <span>如何使用批量打印功能？</span>
      </li>
      <li class="list-item">
        <i class="iconfont icon-circle"></i>
        <span>如何使用批量打印功能？</span>
      </li>
      <li class="list-item">
        <i class="iconfont icon-circle"></i>
        <span>如何使用批量打印功能？</span>
      </li>
      <li class="list-item">
        <i class="iconfont icon-circle"></i>
        <span>如何使用批量打印功能？</span>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "BatchPrintIndex",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.index-container {
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .index-list {
    .list-item {
      margin: 5px 0;
    }
  }
}
/deep/ .iconfont {
  font-size: 10px;
  margin-right: 10px;
  color: #f5821f;
}
</style>